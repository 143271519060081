import React from "react";
import * as ReactDOM from "react-dom/client";

import "./index.css";
// @ts-ignore

// import App from "./App";
import Constrancion from "./Construction";
// import Footer from "./views/components/Footer";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(rootElement);
root.render(
  // <React.StrictMode>
  // <App />
  <Constrancion />
  // </React.StrictMode>
  // useEffectが2回走っちゃうのでStrictModeを廃止します
);

// const footElement = document.getElementById("foot");
// if (!footElement) throw new Error("Failed to find the root element");
// const foot = ReactDOM.createRoot(footElement);
// foot.render(
//   <>
//     {window.location.pathname.indexOf("Bunku") !== 1 ? (
//       <>
//         <Footer />
//       </>
//     ) : (
//       <></>
//     )}
//   </>
// );
