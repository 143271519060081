import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Test: React.VFC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div
              style={{
                backgroundColor: "white",
                color: "black",
                height: "100vh", // ビューポート全体をカバーする
                display: "flex", // Flexboxを使用
                justifyContent: "center", // 横方向の中央揃え
                alignItems: "center", // 縦方向の中央揃え
              }}
            >
              現在メンテナンス期間中です。
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Test;
